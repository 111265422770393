import { addons } from '@storybook/addons'
import managerTheme from './manager-theme'

addons.setConfig({
  theme: managerTheme,
  enableShortcuts: false,
  toolbar: {
    title: { hidden: false },
    zoom: { hidden: true },
    eject: { hidden: false },
    copy: { hidden: false },
    fullscreen: { hidden: true },
  },
})
