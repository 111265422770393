import { create } from '@storybook/theming'
import { lightTheme } from '@mest-fe/ui'

export default create({
  base: 'light',
  brandTitle: 'MEST Widgets',
  brandImage:
    'https://user-images.githubusercontent.com/11304944/182792939-3884ddbf-76e2-4242-94e5-459b46033d43.png',
  appBg: lightTheme.colors.background.value,
  appContentBg: lightTheme.colors.accents_8.value,
  appBorderColor: 'transparent',
  fontBase: lightTheme.fonts.sans.value,
  fontCode: lightTheme.fonts.mono.value,
  textColor: lightTheme.colors.foreground.value,
  barBg: lightTheme.colors.background.value,
})
